<div class="navigation">
    <ul class="nav mx-0 mb-0 mt-0 subnav">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/']">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/influencer']">Für Influencer</a>
      </li>
      <li class="nav-item hasSubMenu">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen']">Für Unternehmen <mat-icon style="visibility: hidden;" #icon>expand_more</mat-icon></a>
        
        <div class="icon-submenu">

        </div>
        <ul class="submenu">
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/social-media-marketing']">Social Media Marketing</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/personal-marketing']">Personal Marketing</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/influencer-marketing']">Influencer Marketing</a>
            </li>
        </ul>
      </li>
      <!-- <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/kontakt']">Kontakt</a>
      </li> -->
      <li class="nav-item ml-3" routerLinkActive="active">
        <a  mat-raised-button class="btn btn-primary" [routerLink]="['/unternehmen/kontakt']">
          Kontakt
        </a>
        <!-- <a *ngIf="!userloggedin" mat-raised-button class="btn btn-primary" [routerLink]="['/login']">
          Login
        </a>
        <a *ngIf="userloggedin" mat-raised-button class="btn btn-primary" (click)="userLogout()">
          <i class="material-icons">exit_to_app</i> Logout
        </a> -->
      </li>
    </ul>
</div>