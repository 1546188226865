import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let Swiper: any;
declare let ScrollMagic: any;
declare let TweenMax: any;

@Component({
    selector: 'app-mobile-nav-cmp',
    templateUrl: 'mobileNav.component.html'
})

export class MobileNavComponent implements OnInit, OnDestroy {
    @ViewChild('toggleButton', {'static': true})
        toggleButton: ElementRef;
    @ViewChild('navOverlay', {'static': true})
        navElement: ElementRef;
    private sidebarVisible = false;
    private transitionInProgress = false;
    overlay: HTMLElement;
    timeouts: any = [];
    controller: any;
    delay = false;
    windowScene: any;
    windowWidth: number;

    @HostListener('window:resize', ['$event'])
    onResize(event) {

        if (!this.delay) {
            if (event.target.innerWidth === this.windowWidth) {
                return;
            }
            this.windowWidth = event.target.innerWidth;
            this.resetWindowScene();
        }
    }

    constructor(private router: Router) {

    }

    ngOnInit() {
        this.controller = new ScrollMagic.Controller();
        this.windowWidth = window.innerWidth;
        this.toggleButton.nativeElement.addEventListener('click', () => {
            this.sidebarToggle();
        });


        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe(() => {
            this.navElement.nativeElement.classList.add('loading');
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.navElement.nativeElement.classList.remove('loading');
            this.resetWindowScene();
            this.sidebarClose();
        });

        this.delay = true;
        const timeout = setTimeout(() => {
            this.buildWindowScene();
            this.delay = false;
        }, 500);
        this.timeouts.push(timeout);
    }

    sidebarClose() {
        if (this.sidebarVisible === false || this.transitionInProgress) {
            return;
        }
        this.transitionInProgress = true;

        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('nav-toggled');
        const $toggle = this.toggleButton.nativeElement;
        $toggle.classList.remove('toggled');
        $toggle.classList.remove('negative');

        const $nav = this.navElement.nativeElement;
        $nav.classList.remove('toggled');
        $nav.classList.remove('fadeBg');

        this.overlay.classList.remove('fadeIn');
        this.timeouts.push(setTimeout(() => {
            this.overlay.remove();
        }, 400));
        setTimeout(() => {
            this.transitionInProgress = false;
            this.sidebarVisible = false;
        }, 400);
    }

    sidebarOpen() {
        if (this.sidebarVisible || this.transitionInProgress) {
            return;
        }
        this.transitionInProgress = true;

        const body = document.getElementsByTagName('body')[0];
        body.classList.add('nav-toggled');
        const $toggle = this.toggleButton.nativeElement;
        $toggle.classList.add('toggled');
        $toggle.classList.add('negative');

        const $nav = this.navElement.nativeElement;

        this.overlay = document.createElement('div');
        this.overlay.classList.add('nav-overlay');
        this.overlay.addEventListener('click', () => {
            this.sidebarClose();
        });
        document.body.prepend(this.overlay);

        this.timeouts.push(setTimeout(() => {
            this.overlay.classList.add('fadeIn');
            $nav.classList.add('toggled');
            $nav.classList.add('fadeBg');
        }, 50));

        setTimeout(() => {
             this.transitionInProgress = false;
             this.sidebarVisible = true;
        }, 400);
    }

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    buildWindowScene() {
        const pageElement = document.getElementsByClassName('page')[0];
        this.windowScene = new ScrollMagic.Scene({
            triggerElement: pageElement,
            triggerHook: 0,
            offset: -25
        })
        .setClassToggle(this.toggleButton.nativeElement, 'light-bg');
        if (pageElement !== undefined) {
            this.windowScene.addTo(this.controller);
        }
    }

    resetWindowScene() {
        this.delay = true;
        const timeout = setTimeout(() => {
            this.controller.destroy(true);
            this.windowScene.destroy();
            this.controller = new ScrollMagic.Controller();
            this.buildWindowScene();
            this.delay = false;
        }, 500);
        this.timeouts.push(timeout);
    }

    ngOnDestroy() {
        window.clearTimeout(this.timeouts);
    }
}
