// import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
const ɵ0 = () => import("./pages/pages.module.ngfactory").then(m => m.PagesModuleNgFactory);
// import { OptinComponent } from './layouts/optin/optin.component';
// import { AuthGuard } from '@/helpers';
export const AppRoutes = [
    // {
    //     path: 'dashboard',
    //     component: AdminLayoutComponent,
    //     canActivate: [AuthGuard],
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: './dashboard/dashboard.module#DashboardModule'
    //         }, {
    //             path: 'components',
    //             loadChildren: './components/components.module#ComponentsModule'
    //         }, {
    //             path: 'forms',
    //             loadChildren: './forms/forms.module#Forms'
    //         }, {
    //             path: 'tables',
    //             loadChildren: './tables/tables.module#TablesModule'
    //         }, {
    //             path: 'maps',
    //             loadChildren: './maps/maps.module#MapsModule'
    //         }, {
    //             path: 'widgets',
    //             loadChildren: './widgets/widgets.module#WidgetsModule'
    //         }, {
    //             path: 'charts',
    //             loadChildren: './charts/charts.module#ChartsModule'
    //         }, {
    //             path: 'calendar',
    //             loadChildren: './calendar/calendar.module#CalendarModule'
    //         }, {
    //             path: '',
    //             loadChildren: './userpage/user.module#UserModule'
    //         }, {
    //             path: '',
    //             loadChildren: './timeline/timeline.module#TimelineModule'
    //         }
    //     ]
    // },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
                path: '',
                loadChildren: ɵ0
            }]
    },
    // {
    //     path: 'optin/:authToken',
    //     component: OptinComponent
    // },
    { path: '**', redirectTo: '' }
];
export { ɵ0 };
