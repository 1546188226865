<app-mobile-nav-cmp></app-mobile-nav-cmp>
<mat-progress-bar
  *ngIf="loading" color="primary" mode="indeterminate">
</mat-progress-bar>
<div class="pageview">
    <nav class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute" color-on-scroll="500">
      <div class="container">
        <div class="navbar-wrapper">
            <div class="logo">
                <a routerLink="/">
                    <img src="/assets/img/logo.png" />
                </a>
            </div>
        </div>
        
        <!-- <div class="collapse navbar-collapse justify-content-end">
          <app-subnav-cmp></app-subnav-cmp>
          <ul *ngIf="!userloggedin" class="navbar-nav">
            <li *ngIf="showLoginButton" class="nav-item" routerLinkActive="active">
              <a mat-button-raised class="nav-link" [routerLink]="['/login']">
                Login
              </a>
            </li>
            <li *ngIf="showRegisterButton" class="nav-item" routerLinkActive="active">
              <a mat-raised-button class="btn btn-primary" [routerLink]="['/registrieren']">
                <i class="material-icons">person_add</i> Jetzt Bewerben
              </a>
            </li>
          </ul>
          <ul *ngIf="userloggedin" class="navbar-nav">
              <li class="nav-item" routerLinkActive="active">
                <a mat-button class="nav-link" (click)="userLogout()">
                  <i class="material-icons">exit_to_app</i> Logout
                </a>
              </li>
            </ul>
        </div> -->
        <app-navigation-cmp></app-navigation-cmp>
      </div>
    </nav>
    <video #videoPlayer loop muted autoplay playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true" poster="./assets/vid/background_vid-poster.jpg"> 
        <!-- <source src="./assets/vid/background_vid.mp4" type="video/mp4"> -->
        <source src="./assets/vid/bg_vid.webm" type="video/webm">
        <source src="./assets/vid/bg_vid.mp4" type="video/mp4">
    </video>
    <router-outlet></router-outlet>
    <div class="mockpage"></div>
    <app-footer-cmp *ngIf="!footerIsExtended" [isFluid]="isFluid"></app-footer-cmp>
    <app-footer-extended-cmp *ngIf="footerIsExtended"></app-footer-extended-cmp>
</div>


