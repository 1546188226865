import { NgModule } from '@angular/core';

import { LazyImageDirective } from '@/helpers/lazyImageDirective';

@NgModule({
    declarations: [ LazyImageDirective ],
    exports: [ LazyImageDirective ]
})

export class LazyImageModule {}
