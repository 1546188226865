import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-footer-extended-cmp',
    templateUrl: 'footerExtended.component.html'
})

export class FooterExtendedComponent {
    date: Date = new Date();
}
