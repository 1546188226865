<footer class="footer">
  <div [ngClass]="isFluid ? 'container-fluid' : 'container'">
    <div class="f-section partner float-left">
      <img src="assets/img/facebook-partner-logo.png" alt="">
      <img src="assets/img/instagram-partner-logo_s.png" alt="">
    </div>
    <div class="f-section copyright float-right" style="font-size:14px;">
      <a class="footer-item" routerLink="/faq">FAQ</a>
      <a class="footer-item" routerLink="/agb">AGB</a>
      <a class="footer-item" routerLink="/impressum">Impressum</a>
      <a class="footer-item" routerLink="/datenschutz">Datenschutz</a>
      <!-- <span style="white-space: nowrap;">&copy;{{date | date: 'yyyy'}}, made with <i class="material-icons" style="color:#E6005F;">favorite</i> by CityHelden.</span> -->
    </div>
  </div>
</footer>
  