import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// import { FacebookModule } from 'ngx-facebook';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatRippleModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';


import { AppComponent } from './app.component';

// import { JwtInterceptor, ErrorInterceptor } from '@/helpers';

// import { SidebarModule } from './sidebar/sidebar.module';
import { FooterExtendedModule } from '@/shared/footer/footerExtended.module';

import { FooterModule } from './shared/footer/footer.module';
import { SubnavModule } from './shared/subnav/subnav.module';
import { MobileNavModule } from './shared/mobileNav/mobileNav.module';
import { NavigationModule } from './shared/navigation/navigation.module';
// import { NavbarModule} from './shared/navbar/navbar.module';
// import { FixedpluginModule} from './shared/fixedplugin/fixedplugin.module';
// import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
// import { OptinComponent } from './layouts/optin/optin.component';


import { AppRoutes } from './app.routing';


@NgModule({
  exports: [
    // MatAutocompleteModule,
    MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    MatCheckboxModule,
    // MatChipsModule,
    // MatStepperModule,
    // MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatPaginatorModule,
    MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    MatRippleModule,
    // MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatSortModule,
    // MatTableModule,
    MatTabsModule,
    // MatToolbarModule,
    MatTooltipModule
  ]
})
export class MaterialModule {}

import { DeviceDetectorModule } from 'ngx-device-detector';
import { LazyImageModule } from './helpers/lazyImageModule';

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, {
          useHash: false
        }),
        // FacebookModule.forRoot(),
        HttpClientModule,
        MaterialModule,
        // SidebarModule,
        // NavbarModule,
        SubnavModule,
        MobileNavModule,
        NavigationModule,
        FooterModule,
        FooterExtendedModule,
        // FixedpluginModule,
        DeviceDetectorModule.forRoot(),
        LazyImageModule
    ],
    declarations: [
        AppComponent,
        // AdminLayoutComponent,
        AuthLayoutComponent,
        // OptinComponent
    ],
    providers: [
      // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: 'googleTagManagerId', useValue: 'GTM-KVR22RG'}
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
