import { Component, OnInit, ElementRef, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { filter } from 'rxjs/operators';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
// import PerfectScrollbar from 'perfect-scrollbar';

// import { AuthenticationService } from '@/services';
import { DynamicLayoutService } from '@/services';
// import { windowTime } from 'rxjs/operators';

declare let TweenMax: any;
declare let ScrollMagic: any;
declare let Power2: any;

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthLayoutComponent implements OnInit, AfterViewInit {
  userloggedin: boolean;
  mobile_menu_visible: any = 0;
  private _router: Subscription;
  isFluid: Boolean = true;
  location: Location;
  routeWithClass: string;

  public footerExtended: Subscription;
  footerIsExtended: boolean;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  public showButtonSub: Subscription;
  showRegisterButton = false;
  showLoginButton = false;

  catchScrollIntent: any;

  controller: any;
  pageScene: any;
  footerScene: any;
  timeouts: any = [];
  delay = false;

  loading: boolean;

  @ViewChild('videoPlayer', { static: true} ) videoplayer: ElementRef;
  shouldntPlay: boolean;

  constructor(
    private router: Router,
    location: Location,
    private element: ElementRef,
    // private authenticationService: AuthenticationService,
    private dynamicLayoutService: DynamicLayoutService,
    private ref: ChangeDetectorRef) {
      this.location = location;
    //   this.authenticationService.currentUser.subscribe((data) => {
    //     if (data) {
    //       this.userloggedin = true;
    //     } else {
    //       this.userloggedin = false;
    //     }
    // });
  }

  ngOnInit() {

    this.controller = new ScrollMagic.Controller();
    this.footerExtended = this.dynamicLayoutService.extendedFooter.subscribe((data) => {
      this.footerIsExtended = data;
      this.resetScenes();
      setTimeout(() => {
        this.ref.markForCheck();
      }, 1);
    });

    this.showButtonSub = this.dynamicLayoutService.showRegisterbutton.subscribe((data) => {
      this.showRegisterButton = data.registerButton;
      this.showLoginButton = data.loginButton;
    });

    const elemMainPanel = <HTMLElement>document.querySelector('.pageview');
    this.location.subscribe((ev: PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
    });

    this.router.events.pipe(
          filter(event => event instanceof NavigationStart)
      ).subscribe(() => {
        this.loading = true;
        window.clearTimeout(this.catchScrollIntent);
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationError)
    ).subscribe(() => {
      this.resetScenes();
      this.loading = false;
      elemMainPanel.scrollTop = 0;
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.ref.markForCheck();
      }, 1);
      this.catchScrollIntent = setTimeout(() => {
        const scroller = document.getElementById('scroller');
        if (scroller) {
          scroller.classList.add('prompt');
        }
      }, 5000);
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.resetScenes();
      this.loading = false;
      elemMainPanel.scrollTop = 0;
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.ref.markForCheck();
      }, 1);
      this.catchScrollIntent = setTimeout(() => {
        const scroller = document.getElementById('scroller');
        if (scroller) {
          scroller.classList.add('prompt');
        }
      }, 5000);
    });
  }

  @HostListener('window:focus', ['$event'])
    onFocus(event: any): void {
      if (!this.shouldntPlay) {
        this.videoplayer.nativeElement.play();
      }
    }

  @HostListener('window:blur', ['$event'])
    onBlur(event: any): void {
      this.videoplayer.nativeElement.pause();
    }

  @HostListener('window:scroll', ['$event'])
    onScroll(event: any): void {
      window.clearTimeout(this.catchScrollIntent);
    }

  ngAfterViewInit() {
    this.buildScenes();
    this.catchScrollIntent = setTimeout(() => {
      const scroller = document.getElementById('scroller');
      if (scroller) {
        scroller.classList.add('prompt');
      }
    }, 5000);
  }

  buildScenes() {
    const pageElement = document.getElementsByClassName('page')[0];
    this.pageScene = new ScrollMagic.Scene({
        triggerElement: pageElement,
        triggerHook: 0
    }).on('enter', () => {
      this.shouldntPlay = true;
      this.videoplayer.nativeElement.pause();
    }).on('leave', () => {
      this.shouldntPlay = false;
      this.videoplayer.nativeElement.play();
    });

    const footerElement = document.getElementsByClassName('footer-extended')[0];
    this.footerScene = new ScrollMagic.Scene({
        triggerElement: footerElement,
        triggerHook: 1
    }).on('enter', () => {
      this.shouldntPlay = false;
      this.videoplayer.nativeElement.play();
    }).on('leave', () => {
      this.shouldntPlay = true;
      this.videoplayer.nativeElement.pause();
    });

    if (pageElement !== undefined) {
      this.pageScene.addTo(this.controller);
    }

    if (pageElement !== undefined) {
        this.footerScene.addTo(this.controller);
    }
  }

  resetScenes() {
      this.delay = true;
      const timeout = setTimeout(() => {
          this.videoplayer.nativeElement.play();
          this.shouldntPlay = false;
          this.controller.destroy(true);
          this.pageScene.destroy();
          this.controller = new ScrollMagic.Controller();
          this.buildScenes();
          this.delay = false;
      }, 500);
      this.timeouts.push(timeout);
  }

  userLogout() {
    // this.authenticationService.logout();
    this.router.navigate(['']);
  }

}
