import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';

// import { AuthenticationService } from '@/services';

declare let Swiper: any;
declare let ScrollMagic: any;
declare let TweenMax: any;

@Component({
    selector: 'app-navigation-cmp',
    templateUrl: 'navigation.component.html'
})

export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {
    timeouts: any = [];
    controller: any;
    delay = false;
    windowWidth: number;
    userloggedin: boolean;
    @ViewChild('icon', {static: true})
        matIcon: any;

    @HostListener('window:resize', ['$event'])
    onResize(event) {

        if (!this.delay) {
            if (event.target.innerWidth === this.windowWidth) {
                return;
            }
            this.windowWidth = event.target.innerWidth;
        }
    }

    constructor(
        private router: Router,
        // private authService: AuthenticationService
        ) {
        // this.authService.currentUser.subscribe((data) => {
        //     if (data) {
        //         this.userloggedin = true;
        //     } else {
        //         this.userloggedin = false;
        //     }
        // });
    }

    ngOnInit() {
        this.controller = new ScrollMagic.Controller();
        this.windowWidth = window.innerWidth;

        this.delay = true;

        const timeout = setTimeout(() => {
            this.delay = false;
        }, 500);
        this.timeouts.push(timeout);
    }

    ngAfterViewInit() {
        this.matIcon._elementRef.nativeElement.style.visibility = 'visible';
    }

    userLogout() {
        // this.authService.logout();
        this.router.navigate(['/']);
    }

    ngOnDestroy() {
        window.clearTimeout(this.timeouts);
    }
}
