import { Injectable } from '@angular/core';

declare global {
    interface Window {
      HubSpotConversations: any;
      hsConversationsSettings: any;
      hsConversationsOnReady: any;
    }
  }

@Injectable({ providedIn: 'root' })
export class HubspotWidgetService {
    private apiReady = false;
    timeOut: any;

    constructor(
    ) {
        window.hsConversationsOnReady = [
            () => {
                window.hsConversationsSettings = {
                    loadImmediately: false
                };
            }
        ];
        this.apiReady = true;
    }

    initWidget() {
        window.clearTimeout(this.timeOut);
        if (this.apiReady
            && typeof window.HubSpotConversations !== 'undefined'
            && typeof window.HubSpotConversations.widget !== 'undefined') {
            const status = window.HubSpotConversations.widget.status();
            if (status.loaded) {
                window.HubSpotConversations.widget.refresh();
            } else {
                window.HubSpotConversations.widget.load();
            }
        }
    }

    destroyWidget() {
        if (this.apiReady
            && typeof window.HubSpotConversations !== 'undefined'
            && typeof window.HubSpotConversations.widget !== 'undefined') {
            const status = window.HubSpotConversations.widget.status();
            if (status.loaded) {
                this.timeOut = setTimeout(() => {
                    window.HubSpotConversations.widget.remove();
                }, 800);
            }
        }
    }

}
