<button mat-button class="navbar-toggler" type="button" #toggleButton>
    <span class="sr-only">Toggle navigation</span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
  </button>
<div #navOverlay id="navOverlay">
    <div class="load-overlay">
        <div class="loader">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
        </div>
    </div>
    <div id="navOverlayInner" class="px-5">
        <ul #mobileNav id="mobileNav">
            <li class="mobile-nav--item">
                <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/']">Home</a>
            </li>
            <li class="mobile-nav--item">
                <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/influencer']">Für Influencer</a>
                <ul class="mobile-nav--subnav">
                    <li class="mobile-nav--item inline-item">
                        <a class="mobile-nav--link" href="https://funnel.cityhelden.com/funnel/ch-influencer/" target="_blank">Jetzt bewerben</a>
                    </li>
                    <!-- <li class="mobile-nav--item inline-item">
                        <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/login']">Login</a>
                    </li> -->
                </ul>
            </li>
            <li class="mobile-nav--item">
                <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen']">Für Unternehmen</a>
                <ul class="mobile-nav--subnav">
                    <li class="mobile-nav--item">
                        <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/social-media-marketing']">Social Media Marketing</a>
                    </li>
                    <li class="mobile-nav--item">
                        <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/personal-marketing']">Personal Marketing</a>
                    </li>
                    <li class="mobile-nav--item">
                        <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/influencer-marketing']">Influencer Marketing</a>
                    </li>
                    
                </ul>
            </li>
            <li class="divider"><hr></li>
            <li class="mobile-nav--item mobile-nav--item-smaller">
                <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/faq']">FAQ</a>
            </li>
            <li class="mobile-nav--item mobile-nav--item-smaller">
                <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/unternehmen/kontakt']">Kontakt</a>
            </li>
            <li class="mobile-nav--item mobile-nav--item-smaller">
                <a class="mobile-nav--link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/agb']">AGB</a>
            </li>
            <li class="divider"><hr></li>
            <li>
                <div class="social-media">
                    <a href="https://www.linkedin.com/company/cityhelden" target="_blank">
                      <i class="fab fa-linkedin"></i>
                    </a>
                    <a href="https://www.facebook.com/cityhelden/" target="_blank">
                      <i class="fab fa-facebook-square"></i>
                    </a>
                    <a href="https://www.instagram.com/cityhelden/" target="_blank">
                      <i class="fab fa-instagram"></i>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>
