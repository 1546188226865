import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DynamicLayoutService {
    public extendedFooter = new BehaviorSubject<boolean>(false);
    public showRegisterbutton = new BehaviorSubject<any>({
        registerButton: false,
        loginButton: true
    });
    public headerSize = new BehaviorSubject<number>(100);
    headerSizeData = this.headerSize.asObservable();
    constructor() { }

    updateHeaderSize(data: number) {
        this.headerSize.next(data);
    }
}
