import * as i0 from "@angular/core";
export class HubspotWidgetService {
    constructor() {
        this.apiReady = false;
        window.hsConversationsOnReady = [
            () => {
                window.hsConversationsSettings = {
                    loadImmediately: false
                };
            }
        ];
        this.apiReady = true;
    }
    initWidget() {
        window.clearTimeout(this.timeOut);
        if (this.apiReady
            && typeof window.HubSpotConversations !== 'undefined'
            && typeof window.HubSpotConversations.widget !== 'undefined') {
            const status = window.HubSpotConversations.widget.status();
            if (status.loaded) {
                window.HubSpotConversations.widget.refresh();
            }
            else {
                window.HubSpotConversations.widget.load();
            }
        }
    }
    destroyWidget() {
        if (this.apiReady
            && typeof window.HubSpotConversations !== 'undefined'
            && typeof window.HubSpotConversations.widget !== 'undefined') {
            const status = window.HubSpotConversations.widget.status();
            if (status.loaded) {
                this.timeOut = setTimeout(() => {
                    window.HubSpotConversations.widget.remove();
                }, 800);
            }
        }
    }
}
HubspotWidgetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HubspotWidgetService_Factory() { return new HubspotWidgetService(); }, token: HubspotWidgetService, providedIn: "root" });
