import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
// import { FacebookService, InitParams } from 'ngx-facebook';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { HubspotWidgetService } from '@/services';

@Component({
    selector: 'app-ch-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  currentWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth !== this.currentWidth) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.currentWidth = event.target.innerWidth;
    }
  }

  constructor(
    private router: Router,
    // private facebookService: FacebookService,
    private hubspotWidgetService: HubspotWidgetService,
    private gtmService: GoogleTagManagerService ) {

  }

    ngOnInit() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.currentWidth = window.innerWidth;
      // this.initFacebookService();

      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      });

      this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        this.currentWidth = window.innerWidth;
      });

      setTimeout(() => {
        this.hubspotWidgetService.destroyWidget();
      }, 200);
    }

    // private initFacebookService(): void {
    //   const initParams: InitParams = { xfbml: true, version: 'v5.0'};
    //   this.facebookService.init(initParams);
    // }

    // auth_response_change_callback(response) {
    //   console.log('Auth:', response);
    // }
}
