import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    date: Date = new Date();
    @Input()
    isFluid: Boolean;
}
