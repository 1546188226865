import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DynamicLayoutService {
    constructor() {
        this.extendedFooter = new BehaviorSubject(false);
        this.showRegisterbutton = new BehaviorSubject({
            registerButton: false,
            loginButton: true
        });
        this.headerSize = new BehaviorSubject(100);
        this.headerSizeData = this.headerSize.asObservable();
    }
    updateHeaderSize(data) {
        this.headerSize.next(data);
    }
}
DynamicLayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicLayoutService_Factory() { return new DynamicLayoutService(); }, token: DynamicLayoutService, providedIn: "root" });
