<footer class="footer footer-extended clearfix">
  <div class="row">
      <div class="container">
          <div class="logo footer-logo clearfix">
              <a routerLink="/">
                  <img src="/assets/img/logo.png" />
              </a>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
          <div class="footer-area">
            <h4 class="footer-title">CityHelden GmbH</h4>
            <p>
              Frankfurt am Main<br>
              Rostock<br>
              Frankfurt (Oder)
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
          <div class="footer-area">
            <h4 class="footer-title">Kontakt</h4>
            <p>
                Tel.: 0335 66 595 27<br>
                E-Mail: <a href="mailto:office@cityhelden.com">office@cityhelden.com</a>
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
          <div class="footer-area">
            <h4 class="footer-title"><a routerLink="/influencer">Für Influencer</a></h4>
            <ul class="footer-menu">
              <li class="footer-menu-item">
                <a routerLink="/registrieren">Jetzt bewerben</a>
              </li>
              <li class="footer-menu-item">
                <a routerLink="/login">Login</a>
              </li>
            </ul>
          </div>
        </div>
       
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
            <div class="footer-area">
              <h4 class="footer-title"><a routerLink="/unternehmen">Für Unternehmen</a></h4>
              <ul class="footer-menu">
                <li class="footer-menu-item">
                  <a routerLink="/unternehmen/social-media-marketing">Social Media Marketing</a>
                </li>
                <li class="footer-menu-item">
                  <a routerLink="/unternehmen/personal-marketing">Personal Marketing</a>
                </li>
                <li class="footer-menu-item">
                  <a routerLink="/unternehmen/influencer-marketing">Influencer Marketing</a>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="container">
      <div class="social-media">
        <a href="https://www.linkedin.com/company/cityhelden" target="_blank">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://www.facebook.com/cityhelden/" target="_blank">
          <i class="fab fa-facebook-square"></i>
        </a>
        <a href="https://www.instagram.com/cityhelden/" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="container">
          <div class="f-section partner float-left">
            <img src="assets/img/facebook-partner-logo.png" alt="">
            <img src="assets/img/instagram-partner-logo_s.png" alt="">
          </div>
          <div class="f-section copyright float-right" style="font-size:14px;">
            <a class="footer-item" routerLink="/faq">FAQ</a>
            <a class="footer-item" routerLink="/agb">AGB</a>
            <a class="footer-item" routerLink="/impressum">Impressum</a>
            <a class="footer-item" routerLink="/datenschutz">Datenschutz</a>
            <!-- <span style="white-space: nowrap;">&copy;{{date | date: 'yyyy'}}, made with <i class="material-icons" style="color:#E6005F;">favorite</i> by CityHelden.</span> -->
          </div>
        </div>
  </div>
  
</footer>
  