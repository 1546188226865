/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "../../shared/footer/footer.component.ngfactory";
import * as i5 from "../../shared/footer/footer.component";
import * as i6 from "../../shared/footer/footerExtended.component.ngfactory";
import * as i7 from "../../shared/footer/footerExtended.component";
import * as i8 from "../../shared/mobileNav/mobileNav.component.ngfactory";
import * as i9 from "../../shared/mobileNav/mobileNav.component";
import * as i10 from "@angular/router";
import * as i11 from "@angular/common";
import * as i12 from "../../helpers/lazyImageDirective";
import * as i13 from "../../shared/navigation/navigation.component.ngfactory";
import * as i14 from "../../shared/navigation/navigation.component";
import * as i15 from "./auth-layout.component";
import * as i16 from "../../services/dynamicLayout.service";
var styles_AuthLayoutComponent = [];
var RenderType_AuthLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthLayoutComponent, data: {} });
export { RenderType_AuthLayoutComponent as RenderType_AuthLayoutComponent };
function View_AuthLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "primary"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "primary"; var currVal_4 = "indeterminate"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AuthLayoutComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer-cmp", [], null, null, null, i4.View_FooterComponent_0, i4.RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i5.FooterComponent, [], { isFluid: [0, "isFluid"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFluid; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AuthLayoutComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer-extended-cmp", [], null, null, null, i6.View_FooterExtendedComponent_0, i6.RenderType_FooterExtendedComponent)), i0.ɵdid(1, 49152, null, 0, i7.FooterExtendedComponent, [], null, null)], null, null); }
export function View_AuthLayoutComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { videoplayer: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-mobile-nav-cmp", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MobileNavComponent_0, i8.RenderType_MobileNavComponent)), i0.ɵdid(2, 245760, null, 0, i9.MobileNavComponent, [i10.Router], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuthLayoutComponent_1)), i0.ɵdid(4, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 20, "div", [["class", "pageview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 9, "nav", [["class", "navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute"], ["color-on-scroll", "500"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "navbar-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i11.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "img", [["src", "/assets/img/logo.png"]], null, null, null, null, null)), i0.ɵdid(13, 16384, null, 0, i12.LazyImageDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(14, 0, null, null, 1, "app-navigation-cmp", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_NavigationComponent_0, i13.RenderType_NavigationComponent)), i0.ɵdid(15, 4440064, null, 0, i14.NavigationComponent, [i10.Router], null, null), (_l()(), i0.ɵeld(16, 0, [[1, 0], ["videoPlayer", 1]], null, 2, "video", [["autoplay", ""], ["loop", ""], ["muted", ""], ["oncanplay", "this.play()"], ["onloadedmetadata", "this.muted = true"], ["playsinline", ""], ["poster", "./assets/vid/background_vid-poster.jpg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "source", [["src", "./assets/vid/bg_vid.webm"], ["type", "video/webm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "source", [["src", "./assets/vid/bg_vid.mp4"], ["type", "video/mp4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(20, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(21, 0, null, null, 0, "div", [["class", "mockpage"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuthLayoutComponent_2)), i0.ɵdid(23, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuthLayoutComponent_3)), i0.ɵdid(25, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_3 = "/"; _ck(_v, 11, 0, currVal_3); _ck(_v, 15, 0); _ck(_v, 20, 0); var currVal_4 = !_co.footerIsExtended; _ck(_v, 23, 0, currVal_4); var currVal_5 = _co.footerIsExtended; _ck(_v, 25, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 11).target; var currVal_2 = i0.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_1, currVal_2); }); }
export function View_AuthLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, [["window", "focus"], ["window", "blur"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onBlur($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:scroll" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_AuthLayoutComponent_0, RenderType_AuthLayoutComponent)), i0.ɵdid(1, 4308992, null, 0, i15.AuthLayoutComponent, [i10.Router, i11.Location, i0.ElementRef, i16.DynamicLayoutService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthLayoutComponentNgFactory = i0.ɵccf("app-layout", i15.AuthLayoutComponent, View_AuthLayoutComponent_Host_0, {}, {}, []);
export { AuthLayoutComponentNgFactory as AuthLayoutComponentNgFactory };
