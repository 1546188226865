import { Routes } from '@angular/router';

// import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
// import { OptinComponent } from './layouts/optin/optin.component';

// import { AuthGuard } from '@/helpers';

export const AppRoutes: Routes = [
    // {
    //     path: 'dashboard',
    //     component: AdminLayoutComponent,
    //     canActivate: [AuthGuard],
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: './dashboard/dashboard.module#DashboardModule'
    //         }, {
    //             path: 'components',
    //             loadChildren: './components/components.module#ComponentsModule'
    //         }, {
    //             path: 'forms',
    //             loadChildren: './forms/forms.module#Forms'
    //         }, {
    //             path: 'tables',
    //             loadChildren: './tables/tables.module#TablesModule'
    //         }, {
    //             path: 'maps',
    //             loadChildren: './maps/maps.module#MapsModule'
    //         }, {
    //             path: 'widgets',
    //             loadChildren: './widgets/widgets.module#WidgetsModule'
    //         }, {
    //             path: 'charts',
    //             loadChildren: './charts/charts.module#ChartsModule'
    //         }, {
    //             path: 'calendar',
    //             loadChildren: './calendar/calendar.module#CalendarModule'
    //         }, {
    //             path: '',
    //             loadChildren: './userpage/user.module#UserModule'
    //         }, {
    //             path: '',
    //             loadChildren: './timeline/timeline.module#TimelineModule'
    //         }
    //     ]
    // },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
        }]
    },
    // {
    //     path: 'optin/:authToken',
    //     component: OptinComponent
    // },
    { path: '**', redirectTo: '' }
];
