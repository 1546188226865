/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../helpers/lazyImageDirective";
import * as i3 from "@angular/router";
import * as i4 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 20, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "f-section partner float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "img", [["alt", ""], ["src", "assets/img/facebook-partner-logo.png"]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i2.LazyImageDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "img", [["alt", ""], ["src", "assets/img/instagram-partner-logo_s.png"]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i2.LazyImageDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 12, "div", [["class", "f-section copyright float-right"], ["style", "font-size:14px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "a", [["class", "footer-item"], ["routerLink", "/faq"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["FAQ"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "a", [["class", "footer-item"], ["routerLink", "/agb"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["AGB"])), (_l()(), i0.ɵeld(16, 0, null, null, 2, "a", [["class", "footer-item"], ["routerLink", "/impressum"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(17, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Impressum"])), (_l()(), i0.ɵeld(19, 0, null, null, 2, "a", [["class", "footer-item"], ["routerLink", "/datenschutz"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Datenschutz"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isFluid ? "container-fluid" : "container"); _ck(_v, 3, 0, currVal_0); var currVal_3 = "/faq"; _ck(_v, 11, 0, currVal_3); var currVal_6 = "/agb"; _ck(_v, 14, 0, currVal_6); var currVal_9 = "/impressum"; _ck(_v, 17, 0, currVal_9); var currVal_12 = "/datenschutz"; _ck(_v, 20, 0, currVal_12); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 11).target; var currVal_2 = i0.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_1, currVal_2); var currVal_4 = i0.ɵnov(_v, 14).target; var currVal_5 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_7 = i0.ɵnov(_v, 17).target; var currVal_8 = i0.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_7, currVal_8); var currVal_10 = i0.ɵnov(_v, 20).target; var currVal_11 = i0.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_10, currVal_11); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer-cmp", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i4.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i0.ɵccf("app-footer-cmp", i4.FooterComponent, View_FooterComponent_Host_0, { isFluid: "isFluid" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
